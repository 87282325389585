import { AxiosResponse } from 'axios'

import { createUseQuery } from 'api/common/createUseQuery'
import { fetchApps } from 'api/fetchers/apps/fetchApps'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { fetchAppsResponse } from 'types/app/app'

export const useFetchApps = createUseQuery({
  queryKey: ApiQueryKeys.APPS,
  fetcher: fetchApps,
  selector: (response?: AxiosResponse<fetchAppsResponse>) => ({
    data: response?.data.data ?? ([] as fetchAppsResponse['data']),
    paginator: response?.data.paginator ?? ({} as fetchAppsResponse['paginator']),
  }),
})
