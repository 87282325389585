// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._ql0_{height:48px}.nSdJk{border-bottom:1px solid #c1c7cd;align-content:center}.HVGpt{padding-inline:16px;text-align:left}.TRl8q:hover{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/pages/tools/toolsTable/tableHeader/TableHeader.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CAGF,OACE,+BAAA,CACA,oBAAA,CAGF,OACE,mBAAA,CACA,eAAA,CAIA,aACE,cAAA","sourcesContent":[".tableRow {\n  height: 48px;\n}\n\n.headerColumn {\n  border-bottom: 1px solid #C1C7CD;\n  align-content: center;\n}\n\n.alignLeft {\n  padding-inline: 16px;\n  text-align: left;\n}\n\n.sortIcon {\n  &:hover {\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableRow": "_ql0_",
	"headerColumn": "nSdJk",
	"alignLeft": "HVGpt",
	"sortIcon": "TRl8q"
};
export default ___CSS_LOADER_EXPORT___;
