// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JFHGB{height:48px}.ghVvt{align-content:center;border-bottom:1px solid #e7eaee}.aJZBa{width:78px}.GcLUn{width:61px}.YGAaQ{width:200px;padding-inline:16px;text-align:left}.A6f_b{padding-inline:16px;text-align:left}.AqW4C{width:104px}.VRVR6{width:32px;height:32px}", "",{"version":3,"sources":["webpack://./src/components/pages/tools/toolsTable/tableBody/TableBody.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CAGF,OACE,oBAAA,CACA,+BAAA,CAGF,OACE,UAAA,CAGF,OACE,UAAA,CAGF,OACE,WAAA,CACA,mBAAA,CACA,eAAA,CAGF,OACE,mBAAA,CACA,eAAA,CAGF,OACE,WAAA,CAGF,OACE,UAAA,CACA,WAAA","sourcesContent":[".tableRow {\n  height: 48px;\n}\n\n.tableColumn {\n  align-content: center;\n  border-bottom: 1px solid #E7EAEE;\n}\n\n.column1 {\n  width: 78px;\n}\n\n.column2 {\n  width: 61px;\n}\n\n.column3 {\n  width: 200px;\n  padding-inline: 16px;\n  text-align: left;\n}\n\n.column4 {\n  padding-inline: 16px;\n  text-align: left;\n}\n\n.column5 {\n  width: 104px;\n}\n\n.iconContainer {\n  width: 32px;\n  height: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableRow": "JFHGB",
	"tableColumn": "ghVvt",
	"column1": "aJZBa",
	"column2": "GcLUn",
	"column3": "YGAaQ",
	"column4": "A6f_b",
	"column5": "AqW4C",
	"iconContainer": "VRVR6"
};
export default ___CSS_LOADER_EXPORT___;
