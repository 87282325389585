import { WppButton, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import styles from './Content.module.scss'
import { useDeleteApp } from '../../../../api/mutations/apps/useDeleteApp'
import { ApiQueryKeys } from '../../../../constants/apiQueryKeys'
import { useToast } from '../../../../hooks/useToast'
import { useToolsContext } from '../../../../hooks/useToolsContext'
import { Flex } from '../../../common/flex/Flex'
import { WarningConfirmationModal } from '../../../common/warningConfirmModal/WarningConfirmationModal'
import EmptyState from '../emptyState/EmptyState'
import Pagination from '../pagination/Pagination'
import SideModal from '../sideModal/SideModal'
import TableBody from '../toolsTable/tableBody/TableBody'
import TableHeader from '../toolsTable/tableHeader/TableHeader'

const Content = () => {
  const warningModalTitle = 'Delete tool information?'

  const {
    setOpenWarningModal,
    openWarningModal,
    paginatedData,
    appId,
    setAppId,
    setOpenSideModal,
    setSideModalTitle,
    isPaginatedDataLoading,
    setIsEditing,
    appName,
  } = useToolsContext()
  const { mutateAsync: deleteApp } = useDeleteApp()
  const { showToast } = useToast()
  const queryClient = useQueryClient()
  const [warningModalBodyText, setWarningModalBodyText] = useState('')

  const handleDeleteApp = async () => {
    try {
      await deleteApp({ appId })
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.APPS] })
      showToast({
        message: 'AI Tooldeleted successfully',
        type: 'success',
        duration: 4000,
      })
    } catch (err) {
      showToast({
        message: 'There was an error deleting the app',
        type: 'error',
        duration: 4000,
      })
      console.error(err)
    } finally {
      setOpenWarningModal(false)
      setAppId('')
    }
  }

  useEffect(() => {
    const text = `The tool information for ${appName} will be deleted and the tool will be removed from the toolbar.`
    setWarningModalBodyText(text)
  }, [appName])

  return (
    <div className={styles.pageContainer}>
      <Flex justify="between" className={styles.titleBtnWrapper}>
        <WppTypography type="3xl-heading">AI Tools</WppTypography>
        <WppButton
          variant="primary"
          size="m"
          type="button"
          onClick={() => {
            setOpenSideModal(true)
            setIsEditing(false)
            setSideModalTitle('Add tool')
          }}
        >
          Add Tool
        </WppButton>
      </Flex>
      <Flex gap={24} justify="between" direction="column" className={styles.tableContainer}>
        <table className={styles.table}>
          <TableHeader />
          {isPaginatedDataLoading ? (
            <tbody className={styles.spinnerContainer}>
              <tr>
                <td>
                  <WppSpinner size="m" />
                </td>
              </tr>
            </tbody>
          ) : paginatedData?.length === 0 ? (
            <tbody>
              <tr>
                <td>
                  <EmptyState />
                </td>
              </tr>
            </tbody>
          ) : (
            <TableBody />
          )}
        </table>
        <Pagination />
      </Flex>
      <WarningConfirmationModal
        title={warningModalTitle}
        onClose={() => setOpenWarningModal(false)}
        open={openWarningModal}
        bodyText={warningModalBodyText}
        onConfirm={() => {
          handleDeleteApp()
        }}
      />
      <SideModal />
    </div>
  )
}

export default Content
