import { settingsApi } from 'api'

import { PatchAppData, PatchBodyData } from '../../../types/app/app'
import { CancelableRequestProducer } from '../../common/types'

export interface Props {
  appId: string
  appData: PatchBodyData
}

export const patchApp = ({ appId, appData }: Props): CancelableRequestProducer<PatchAppData> =>
  settingsApi.patch(`/apps/${appId}`, appData)
