import { useOs } from '@wpp-open/react'
import { useEffect, useState } from 'react'

import Content from './content/Content'
import { useFetchApps } from '../../../api/queries/apps/useFetchApps'
import { ToolsProvider } from '../../../contexts/ToolsContext'
import { AppAPI, AppData } from '../../../types/app/app'

const Tools = () => {
  const { osContext } = useOs()
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortByName, setSortByName] = useState(false)
  const [appName, setAppName] = useState('')
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { data: appsResponse, isLoading: isPaginatedDataLoading } = useFetchApps({
    params: {
      itemsPerPage: itemsPerPage,
      page: page,
      order_by: sortByName ? 'name' : '',
      tenantId: osContext.tenant.id,
      externalUser: (osContext.userDetails as any)?.isExternal,
    },
  })

  const initialAppData: AppData = {
    name: '',
    appUrl: '',
    tenantId: osContext?.tenant?.id,
    description: '',
    type: 'iframe',
    width: 760,
    height: 600,
    resizable: true,
    iconUrl: '',
    inProjectContext: true,
    accessibleToExternalUsers: false,
    enabled: true,
  }

  const [appData, setAppData] = useState<AppData>(initialAppData)
  const [sortOrder, setSortOrder] = useState('')
  const [paginatedData, setPaginatedData] = useState<AppAPI[]>([])
  const [allItemsCount, setAllItemsCount] = useState<number>(0)
  const [openWarningModal, setOpenWarningModal] = useState(false)
  const [appId, setAppId] = useState('')
  const [openSideModal, setOpenSideModal] = useState(false)
  const [sideModalTitle, setSideModalTitle] = useState('Add tool')

  useEffect(() => {
    setPaginatedData(appsResponse?.data || [])
    setAllItemsCount(appsResponse?.paginator?.totalItems || 0)
  }, [appsResponse])

  const toolsValues = {
    paginatedData,
    setPaginatedData,
    allItemsCount,
    page,
    setPage,
    itemsPerPage,
    setItemsPerPage,
    sortOrder,
    setSortOrder,
    openWarningModal,
    setOpenWarningModal,
    appId,
    setAppId,
    openSideModal,
    setOpenSideModal,
    sideModalTitle,
    setSideModalTitle,
    appData,
    setAppData,
    initialAppData,
    sortByName,
    setSortByName,
    isPaginatedDataLoading,
    tenantId: osContext.tenant.id,
    appName,
    setAppName,
    isEditing,
    setIsEditing,
  }

  return (
    <ToolsProvider value={toolsValues}>
      <Content />
    </ToolsProvider>
  )
}

export default Tools
