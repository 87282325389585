// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FWbV6{width:calc(100% - 40px);height:calc(100% - 96px);padding:20px;position:absolute}.nxms0{width:400px}.JA9lT::part(typography){text-align:center}", "",{"version":3,"sources":["webpack://./src/components/pages/tools/emptyState/EmptyState.module.scss"],"names":[],"mappings":"AAAA,OACE,uBAAA,CACA,wBAAA,CACA,YAAA,CACA,iBAAA,CAGF,OACE,WAAA,CAIA,yBACE,iBAAA","sourcesContent":[".container {\n  width: calc(100% - 40px);\n  height: calc(100% - 96px);\n  padding: 20px;\n  position: absolute;\n}\n\n.contentContainer {\n  width: 400px;\n}\n\n.text {\n  &::part(typography) {\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FWbV6",
	"contentContainer": "nxms0",
	"text": "JA9lT"
};
export default ___CSS_LOADER_EXPORT___;
