// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oKi3Y{padding-top:13px}.HmjDz{width:100%}.pIxWE{padding-left:42px}.vnANx::part(typography){color:#8b919a}", "",{"version":3,"sources":["webpack://./src/components/pages/tools/sideModal/SideModal.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CAGF,OACE,UAAA,CAGF,OACE,iBAAA,CAIA,yBACE,aAAA","sourcesContent":[".container {\n  padding-top: 13px;\n}\n\n.textContainer {\n  width: 100%\n}\n\n.subtextContainer {\n  padding-left: 42px;\n}\n\n.subtext {\n  &::part(typography) {\n    color: #8B919A;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "oKi3Y",
	"textContainer": "HmjDz",
	"subtextContainer": "pIxWE",
	"subtext": "vnANx"
};
export default ___CSS_LOADER_EXPORT___;
