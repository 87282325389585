import { useOs } from '@wpp-open/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import GeneralPage from 'components/pages/general/General'
import Tools from 'components/pages/tools/Tools'

export function App() {
  // TODO check for settings permissions

  // const { hasAccessToChatAssistant } = useHasPermissions()

  // if (!hasAccessToChatAssistant) {
  //   return null
  // }

  const { osContext } = useOs()

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <Routes>
        <Route path="/" element={<GeneralPage />} />
        <Route path="/services" element={<GeneralPage />} />
        <Route path="/tools" element={<Tools />} />
      </Routes>
      {/* <div className={styles.assistantContainer}> */}

      {/*
      <div id="modals" />
*/}
      {/* </div> */}
    </BrowserRouter>
  )
}
