import { ActionConfig } from '@platform-ui-kit/components-library'
import { WppInput, WppSideModal, WppToggle, WppTypography } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { FormEvent, useEffect, useState } from 'react'

import styles from './SideModal.module.scss'
import { useCreateApp } from '../../../../api/mutations/apps/useCreateApp'
import { usePatchApp } from '../../../../api/mutations/apps/usePatchApp'
import { ApiQueryKeys } from '../../../../constants/apiQueryKeys'
import { useToast } from '../../../../hooks/useToast'
import { useToolsContext } from '../../../../hooks/useToolsContext'
import { AppAPI, PatchAppData } from '../../../../types/app/app'
import { Flex } from '../../../common/flex/Flex'
import IconSelect from '../../../common/iconSelect/IconSelect'

const SideModal = () => {
  const {
    paginatedData,
    openSideModal,
    sideModalTitle,
    setOpenSideModal,
    appData,
    setAppData,
    initialAppData,
    appId,
    setAppId,
    isEditing,
    setIsEditing,
  } = useToolsContext()
  const { mutateAsync: createApp } = useCreateApp()
  const { mutateAsync: updateApp } = usePatchApp()
  const { showToast } = useToast()
  const [appDataById, setAppDataById] = useState<AppAPI | undefined>(undefined)
  const [dataForUpdate, setDataForUpdate] = useState<PatchAppData>({} as PatchAppData)
  const [nameInputValue, setNameInputValue] = useState('')
  const [urlInputValue, setUrlInputValue] = useState('')
  const [isInProject, setIsInProject] = useState(true)
  const [forExternalUsers, setForExternalUsers] = useState(true)
  const [icon, setIcon] = useState<string>('')
  const [nameInputMessage, setNameInputMessage] = useState<string>('')
  const [nameInputMessageType, setNameInputMessageType] = useState<'error' | 'warning' | undefined>(undefined)
  const [urlInputMessage, setUrlInputMessage] = useState<string>('')
  const [urlInputMessageType, setUrlInputMessageType] = useState<'error' | 'warning' | undefined>(undefined)
  const [iconInputMessage, setIconInputMessage] = useState<string>('')
  const [iconInputMessageType, setIconInputMessageType] = useState<'error' | 'warning' | undefined>(undefined)
  const [errorOnCreateApp, setErrorOnCreateApp] = useState<string[]>([])
  const queryClient = useQueryClient()

  useEffect(() => {
    if (errorOnCreateApp.length > 0) {
      checkFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorOnCreateApp])

  const handleSaveApp = () => {
    const canSubmit = checkFields()
    if (!canSubmit) return
    isEditing ? handleUpdateApp() : handleCreateApp()
  }

  const checkFields = () => {
    let canSubmit = true

    if (!nameInputValue) {
      setNameInputMessage('This field is required')
      setNameInputMessageType('error')
      canSubmit = false
    }

    if (errorOnCreateApp.includes('name')) {
      setNameInputMessage('AI Tool with this name already exists')
      setNameInputMessageType('error')
      setErrorOnCreateApp([])
      canSubmit = false
    }

    if (nameInputValue.length > 20) {
      setNameInputMessage('This field must be less than 20 characters')
      setNameInputMessageType('error')
      canSubmit = false
    }

    if (!urlInputValue) {
      setUrlInputMessage('This field is required')
      setUrlInputMessageType('error')
      canSubmit = false
    }

    if (errorOnCreateApp.includes('app_url')) {
      setUrlInputMessage('AI Tool with this URL already exists')
      setUrlInputMessageType('error')
      setErrorOnCreateApp([])
      canSubmit = false
    }

    if (!icon) {
      setIconInputMessage('Please, select an icon to continue')
      setIconInputMessageType('error')
      canSubmit = false
    }

    return canSubmit
  }

  const handleCloseSidebar = () => {
    setOpenSideModal(false)
    setAppId('')
    setIsEditing(false)
    setTimeout(() => {
      setAppData(initialAppData)
      setNameInputValue('')
      setNameInputMessage('')
      setNameInputMessageType(undefined)
      setUrlInputValue('')
      setUrlInputMessage('')
      setUrlInputMessageType(undefined)
      setIsInProject(true)
      setForExternalUsers(true)
      setIcon('')
      setIconInputMessage('')
      setIconInputMessageType(undefined)
      setDataForUpdate({} as PatchAppData)
      setAppId('')
      setAppDataById(undefined)
    }, 500)
  }

  const buttonsConfig: ActionConfig[] = [
    {
      label: 'Cancel',
      onClick: () => handleCloseSidebar(),
      variant: 'secondary',
      size: 'm',
    },
    {
      label: 'Save',
      onClick: () => handleSaveApp(),
      variant: 'primary',
      size: 's',
    },
  ]

  const handleCreateApp = async () => {
    try {
      const response = await createApp(appData)
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.APPS] })
      showToast({
        message: 'AI Tool created successfully',
        type: 'success',
        duration: 4000,
      })
      handleCloseSidebar()
      return response.data
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.data?.detail?.length > 0) {
          for (let i = 0; i < err.response?.data?.detail.length; i++) {
            if (err.response?.data?.detail[i]?.type === 'value_error') {
              const errorDetails = err.response?.data?.detail.map((detail: any) => detail.loc[1])
              setErrorOnCreateApp(errorDetails)
            }
          }
        } else {
          showToast({
            message: 'There was an error creating the app',
            type: 'error',
            duration: 4000,
          })
        }

        return err.response?.status
      }
      console.error(err)
    }
  }

  const handleUpdateApp = async () => {
    const updatedAppData = {
      appData: dataForUpdate.appData,
      appId: appId,
    }

    try {
      const response = await updateApp(updatedAppData)
      queryClient.setQueriesData({ queryKey: [ApiQueryKeys.APPS] }, (oldData: any) => {
        return {
          ...oldData,
          data: {
            ...oldData.data,
            data: oldData.data.data.map((app: any) => {
              if (app.id === appId) {
                return {
                  ...app,
                  ...updatedAppData.appData,
                }
              }
              return app
            }),
          },
        }
      })
      showToast({
        message: 'AI Tool updated successfully',
        type: 'success',
        duration: 4000,
      })
      handleCloseSidebar()
      return response.data
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.data?.detail?.length > 0) {
          for (let i = 0; i < err.response?.data?.detail.length; i++) {
            if (err.response?.data?.detail[i]?.type === 'value_error') {
              const errorDetails = err.response?.data?.detail.map((detail: any) => detail.loc[1])
              setErrorOnCreateApp(errorDetails)
            }
          }
        } else {
          showToast({
            message: 'There was an error updating the app',
            type: 'error',
            duration: 4000,
          })
        }
        return err.response?.status
      }
      console.error(err)
    }
  }

  useEffect(() => {
    if (appDataById) {
      setDataForUpdate({ appData: {} } as PatchAppData)
      if (appDataById.name !== nameInputValue) {
        setDataForUpdate(prevState => ({
          ...prevState,
          appData: {
            ...prevState.appData,
            name: nameInputValue,
          },
        }))
      }

      if (appDataById.appUrl !== urlInputValue) {
        setDataForUpdate(prevState => ({
          ...prevState,
          appData: {
            ...prevState.appData,
            appUrl: urlInputValue,
          },
        }))
      }

      if (appDataById.inProjectContext !== isInProject) {
        setDataForUpdate(prevState => ({
          ...prevState,
          appData: {
            ...prevState.appData,
            inProjectContext: isInProject,
          },
        }))
      }

      if (appDataById.accessibleToExternalUsers !== forExternalUsers) {
        setDataForUpdate(prevState => ({
          ...prevState,
          appData: {
            ...prevState.appData,
            accessibleToExternalUsers: forExternalUsers,
          },
        }))
      }

      if (appDataById.iconUrl !== icon) {
        setDataForUpdate(prevState => ({
          ...prevState,
          appData: {
            ...prevState.appData,
            iconUrl: icon,
          },
        }))
      }
    } else {
      setAppData({
        ...appData,
        name: nameInputValue,
        description: nameInputValue,
        appUrl: urlInputValue,
        inProjectContext: isInProject,
        accessibleToExternalUsers: forExternalUsers,
        iconUrl: icon,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameInputValue, urlInputValue, isInProject, forExternalUsers, icon])

  useEffect(() => {
    if (appId && isEditing) {
      const appDataById = paginatedData.find((app: AppAPI) => app.id === appId)
      setAppDataById(appDataById)
      if (!appDataById) return
      setNameInputValue(appDataById.name)
      setUrlInputValue(appDataById.appUrl)
      setIsInProject(appDataById.inProjectContext ?? false)
      setForExternalUsers(appDataById.accessibleToExternalUsers ?? true)
      setIcon(appDataById.iconUrl ?? '')
    }
  }, [appId, isEditing, paginatedData])

  const handleNameInput = (event: FormEvent<HTMLWppInputElement>) => {
    setNameInputValue((event.target as HTMLInputElement).value)
    if (nameInputMessage) {
      setNameInputMessage('')
      setNameInputMessageType(undefined)
    }
  }

  const handleUrlInput = (event: FormEvent<HTMLWppInputElement>) => {
    setUrlInputValue((event.target as HTMLInputElement).value)
    if (urlInputMessage) {
      setUrlInputMessage('')
      setUrlInputMessageType(undefined)
    }
  }

  return (
    <WppSideModal open={openSideModal} size="l" actionsConfig={buttonsConfig} onWppSideModalClose={handleCloseSidebar}>
      <div slot="header">
        <WppTypography type="2xl-heading">{sideModalTitle}</WppTypography>
      </div>
      <Flex direction="column" gap={40} className={styles.container} slot="body">
        <div className={styles.textContainer}>
          <WppTypography type="s-body" tag="p">
            If you have any questions regarding tool information or adding a tool, please contact the support team
          </WppTypography>
          <WppTypography type="s-body" tag="p">
            via intercom.
          </WppTypography>
        </div>
        <Flex direction="row" gap={24}>
          <IconSelect
            label="Icon*"
            icon={icon}
            setIcon={setIcon}
            iconInputMessage={iconInputMessage}
            setIconInputMessage={setIconInputMessage}
            iconInputMessageType={iconInputMessageType}
            setIconInputMessageType={setIconInputMessageType}
          />
          <WppInput
            name="name"
            labelConfig={{ text: 'Name (max 20 characters)*' }}
            placeholder="Enter the tool name"
            required={true}
            type="text"
            message={nameInputMessage}
            messageType={nameInputMessageType}
            size="m"
            value={nameInputValue}
            onInput={event => handleNameInput(event)}
          />
        </Flex>
        <WppInput
          labelConfig={{ text: 'URL*' }}
          placeholder="Paste in the tool URL"
          required={true}
          message={urlInputMessage}
          messageType={urlInputMessageType}
          type="url"
          size="m"
          value={urlInputValue}
          onInput={event => handleUrlInput(event)}
        />
        <Flex direction="column" gap={16}>
          <WppTypography type="s-strong">Access conditions</WppTypography>
          <Flex direction="column">
            <WppToggle
              name="toggle"
              required={true}
              checked={isInProject}
              labelConfig={{
                text: 'Only available in a project',
              }}
              onWppChange={({ detail: { checked } }) => setIsInProject(checked)}
            />
            <div className={styles.subtextContainer}>
              <WppTypography className={styles.subtext} type="s-midi" tag="span">
                Outside projects the tool will remain visible but the user will not be able to open it.
              </WppTypography>
            </div>
          </Flex>
          <Flex direction="column">
            <WppToggle
              name="toggle"
              required={true}
              checked={!forExternalUsers}
              labelConfig={{
                text: 'Only available to internal users',
              }}
              onWppChange={({ detail: { checked } }) => setForExternalUsers(!checked)}
            />
            <div className={styles.subtextContainer}>
              <WppTypography className={styles.subtext} type="s-midi" tag="span">
                Only internal users will be able to see and open the tool, external users will not.{' '}
              </WppTypography>
            </div>
          </Flex>
        </Flex>
      </Flex>
    </WppSideModal>
  )
}

export default SideModal
