import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useMemo } from 'react'

import { QueryFetcher, QueryFetcherParams, QueryFetcherResponse } from 'api/common/types'

export function createUseQuery<F extends QueryFetcher, S>({
  queryKey,
  fetcher,
  selector,
}: {
  queryKey: string
  fetcher: F
  selector: (response?: QueryFetcherResponse<F>) => S
}) {
  return function useCustomQuery(
    options: { params?: QueryFetcherParams<F>; suspense?: boolean } & Omit<
      UseQueryOptions<QueryFetcherResponse<F>, AxiosError, QueryFetcherResponse<F>, [string, QueryFetcherParams<F>]>,
      'queryKey' | 'queryFn' | 'queryHash' | 'queryKeyHashFn' | 'select'
    > = {},
  ) {
    const { params = {} as any, enabled = true, suspense = false, ...queryOptions } = options

    // Enable Suspense by passing suspense: true to useQuery options
    const { data, isLoading, ...rest } = useQuery(
      [queryKey, params],
      ({ signal }) => fetcher(params)(signal) as QueryFetcherResponse<F>,
      {
        enabled,
        suspense, // Enable Suspense here
        ...queryOptions,
      },
    )

    return {
      ...rest,
      isLoading: enabled && isLoading,
      data: useMemo(() => selector(data), [data]),
      response: data,
    }
  }
}
