export const TableHeaderData = [
  {
    title: 'Enable',
    width: '78px',
  },
  {
    title: 'Icon',
    width: '61px',
  },
  {
    title: 'Name',
    width: '200px',
  },
  {
    title: 'Access Conditions',
    width: '',
  },
  {
    title: '',
    width: '104px',
  },
]
