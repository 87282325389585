import { PaginationChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppPagination } from '@platform-ui-kit/components-library-react'

import { useToolsContext } from '../../../../hooks/useToolsContext'

const Pagination = () => {
  const { allItemsCount, page, setPage, setItemsPerPage } = useToolsContext()

  const handlePaginationChange = (event: CustomEvent<PaginationChangeEventDetail>) => {
    setPage(event.detail.page)
    setItemsPerPage(event.detail.itemsPerPage)
  }

  return (
    <WppPagination
      count={allItemsCount}
      itemsPerPage={[10, 20, 30, 50, 100]}
      activePageNumber={page}
      onWppChange={handlePaginationChange}
    />
  )
}

export default Pagination
