import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppDivider,
  WppIconImage,
  WppListItem,
  WppPopover,
  WppInput,
  WppTypography,
  WppIconSearch,
  WppIconClose,
  WppInlineMessage,
} from '@platform-ui-kit/components-library-react'
import * as Icons from '@platform-ui-kit/components-library-react'
import { useRef, useState, useMemo, useCallback } from 'react'

import styles from './IconSelect.module.scss'
import { getIconComponent, formatIconName } from '../../../utils/tools'
import { Flex } from '../flex/Flex'

interface IconSelectProps {
  label?: string
  icon?: string
  setIcon?: (icon: string) => void
  iconInputMessage?: string
  setIconInputMessage?: (message: string) => void
  iconInputMessageType?: 'error' | 'warning' | undefined
  setIconInputMessageType?: (messageType: 'error' | 'warning' | undefined) => void
}

const IconSelect = ({
  label,
  icon,
  setIcon,
  iconInputMessage,
  setIconInputMessage,
  iconInputMessageType,
  setIconInputMessageType,
}: IconSelectProps) => {
  const defaultPopoverRef = useRef<HTMLWppPopoverElement>(null)
  const [searchInput, setSearchInput] = useState('')
  const searchErrorMessage = 'No icons found'

  const handleSearchChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    setSearchInput(event.target.value)
  }

  const icons = useMemo(() => {
    return Object.keys(Icons).filter(
      iconName =>
        iconName.includes('WppIcon') &&
        iconName !== 'WppIconButton' &&
        (searchInput === '' || iconName.toLowerCase().includes(searchInput.toLowerCase())),
    )
  }, [searchInput])

  const handleSelectIcon = (iconName: string) => {
    setIcon && setIcon(iconName)
    setIconInputMessage && setIconInputMessage('')
    setIconInputMessageType && setIconInputMessageType(undefined)
    defaultPopoverRef?.current?.closePopover()
  }

  const getIcon = useCallback((iconName: string) => {
    const IconComponent = getIconComponent(iconName)
    return IconComponent ? <IconComponent color="#4D5358" /> : null
  }, [])

  return (
    <Flex direction="column" className={styles.container}>
      <div className={styles.labelContainer}>
        <WppTypography type="s-strong" tag="p">
          {label ?? 'Icon'}
        </WppTypography>
      </div>
      <WppPopover
        config={{ appendTo: () => document.querySelector('#root')! }}
        ref={defaultPopoverRef}
        dropdownWidth="220"
        externalClass={styles.popover}
      >
        <Flex direction="row" justify="center" align="center" className={styles.iconContainer} slot="trigger-element">
          <Flex
            direction="row"
            justify="center"
            align="center"
            className={styles.grayCircle}
            style={{ border: iconInputMessage ? '1px solid #FF0000' : undefined }}
          >
            {icon ? getIcon(icon) : <WppIconImage />}
          </Flex>
        </Flex>
        <div>
          <WppInput
            name="search"
            placeholder="Search"
            value={searchInput}
            className={styles.search}
            onWppChange={handleSearchChange}
          >
            <WppIconSearch slot="icon-start" aria-label="Search icon" />
            {searchInput.length !== 0 && (
              <WppIconClose
                slot="icon-end"
                aria-label="Clear icon"
                onClick={() => {
                  setSearchInput('')
                }}
              />
            )}
          </WppInput>
          <WppDivider />
          <div className={styles.dropdownContainer}>
            {icons.length === 0 ? (
              <WppTypography type="s-body" color="#121619">
                {searchErrorMessage}
              </WppTypography>
            ) : (
              icons.map(iconName => {
                const IconComponent = getIcon(iconName)
                return (
                  <WppListItem
                    key={iconName}
                    className={styles.listItem}
                    onWppChangeListItem={() => {
                      handleSelectIcon(iconName)
                    }}
                  >
                    <div slot="left">{IconComponent && IconComponent}</div>
                    <div slot="label">
                      <WppTypography type="s-body" color="#121619">
                        {formatIconName(iconName)}
                      </WppTypography>
                    </div>
                  </WppListItem>
                )
              })
            )}
          </div>
        </div>
      </WppPopover>
      <WppInlineMessage
        size="s"
        message={iconInputMessage}
        type={iconInputMessageType}
        hideCloseBtn={true}
        className={styles.inlineMessage}
      />
    </Flex>
  )
}

export default IconSelect
