import * as Icons from '@platform-ui-kit/components-library-react'
import React from 'react'

type IconsMap = Record<string, React.ComponentType<any>>

export const getIconComponent = (iconName: string) => {
  const iconsMap = Icons as IconsMap
  const IconComponent = iconsMap[iconName]
  return IconComponent ?? null
}

export const formatIconName = (iconName: string) => {
  return iconName
    ? iconName
        .replace(/^WppIcon/, '')
        .replace(/([A-Z])/g, ' $1')
        .trim()
    : ''
}
