import { WppIconTableSort, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import styles from './TableHeader.module.scss'
import { TableHeaderData } from './TableHeaderData'
import { useToolsContext } from '../../../../../hooks/useToolsContext'
import { Flex } from '../../../../common/flex/Flex'

const TableHeader = () => {
  const { sortByName, setSortByName } = useToolsContext()

  return (
    <thead>
      <tr className={styles.tableRow}>
        {TableHeaderData.map((column, index) => (
          <th
            key={index}
            className={clsx(styles.headerColumn, (index === 2 || index === 3) && styles.alignLeft)}
            style={{ width: column.width }}
          >
            {column.title !== 'Name' ? (
              <WppTypography type="s-strong">{column.title}</WppTypography>
            ) : (
              <Flex gap={4} align="center">
                <WppTypography type="s-strong">{column.title}</WppTypography>
                <WppIconTableSort
                  className={styles.sortIcon}
                  onClick={() => {
                    setSortByName(!sortByName)
                  }}
                />
              </Flex>
            )}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHeader
