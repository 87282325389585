import {
  WppActionButton,
  WppIconEdit,
  WppIconTrash,
  WppSpinner,
  WppTag,
  WppToggle,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import clsx from 'clsx'
import { useCallback } from 'react'

import styles from './TableBody.module.scss'
import { usePatchApp } from '../../../../../api/mutations/apps/usePatchApp'
import { ApiQueryKeys } from '../../../../../constants/apiQueryKeys'
import { useToast } from '../../../../../hooks/useToast'
import { useToolsContext } from '../../../../../hooks/useToolsContext'
import { AppAPI } from '../../../../../types/app/app'
import { getIconComponent } from '../../../../../utils/tools'
import { Flex } from '../../../../common/flex/Flex'

const TableBody = () => {
  const {
    initialAppData,
    paginatedData,
    setOpenWarningModal,
    appId,
    setAppId,
    setAppData,
    setOpenSideModal,
    setSideModalTitle,
    setAppName,
    setIsEditing,
  } = useToolsContext()
  const { mutateAsync: updateApp, isLoading } = usePatchApp()
  const { showToast } = useToast()
  const queryClient = useQueryClient()

  const getIcon = useCallback((iconName: string) => {
    const IconComponent = getIconComponent(iconName)
    return IconComponent ? <IconComponent color="#4D5358" /> : null
  }, [])

  const handleEnableDisableApp = async (appId: string, enabled: boolean) => {
    const updatedAppData = {
      appData: {
        enabled: enabled,
      },
      appId: appId,
    }

    try {
      const response = await updateApp(updatedAppData)
      queryClient.setQueriesData({ queryKey: [ApiQueryKeys.APPS] }, (oldData: any) => {
        return {
          ...oldData,
          data: {
            ...oldData.data,
            data: oldData.data.data.map((app: any) => {
              if (app.id === appId) {
                return {
                  ...app,
                  ...updatedAppData.appData,
                }
              }
              return app
            }),
          },
        }
      })
      showToast({
        message: `AI Tool ${updatedAppData.appData.enabled ? 'enabled' : 'disabled'} successfully`,
        type: 'success',
        duration: 4000,
      })
      setAppData(initialAppData)
      setAppId('')
      return response.data
    } catch (err) {
      if (isAxiosError(err)) {
        showToast({
          message: `There was an error ${updatedAppData.appData.enabled ? 'enabling' : 'disabling'} the app`,
          type: 'error',
          duration: 4000,
        })
        setAppData(initialAppData)
        setAppId('')
        return err.response?.status
      }
      console.error(err)
    }
  }

  return (
    <tbody>
      {paginatedData?.map((row: AppAPI, index: number) => (
        <tr key={index} className={styles.tableRow}>
          <td className={clsx(styles.tableColumn, styles.column1)}>
            <Flex justify="center">
              {isLoading && appId === row.id ? (
                <WppSpinner size="s" />
              ) : (
                <WppToggle
                  checked={row.enabled}
                  key={row.id}
                  onWppChange={() => {
                    setAppId(row.id)
                    handleEnableDisableApp(row?.id, !row.enabled)
                  }}
                />
              )}
            </Flex>
          </td>
          <td className={clsx(styles.tableColumn, styles.column2)}>
            <Flex justify="center">
              <Flex justify="center" align="center" className={styles.iconContainer}>
                {row.iconUrl ? getIcon(row.iconUrl) : null}
              </Flex>
            </Flex>
          </td>
          <td className={clsx(styles.tableColumn, styles.column3)}>
            <WppTypography type="s-body">{row.name}</WppTypography>
          </td>
          <td className={clsx(styles.tableColumn, styles.column4)}>
            <Flex direction="row" gap={4}>
              {row.inProjectContext && <WppTag label="Only in a project" variant="neutral" />}
              {!row.accessibleToExternalUsers && <WppTag label="Only internal users" variant="neutral" />}
            </Flex>
          </td>
          <td className={clsx(styles.tableColumn, styles.column5)}>
            <Flex gap={8} justify="center">
              <Flex justify="center" align="center" className={styles.iconContainer}>
                <WppActionButton
                  variant="secondary"
                  onClick={() => {
                    setAppId(row.id)
                    setAppName(row.name)
                    setOpenWarningModal(true)
                  }}
                >
                  <WppIconTrash />
                </WppActionButton>
              </Flex>
              <Flex justify="center" align="center" className={styles.iconContainer}>
                <WppActionButton variant="primary">
                  <WppIconEdit
                    onClick={() => {
                      setOpenSideModal(true)
                      setAppId(row.id)
                      setSideModalTitle('Edit tool')
                      setIsEditing(true)
                    }}
                  />
                </WppActionButton>
              </Flex>
            </Flex>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableBody
