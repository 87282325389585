// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".N5_q1{width:100%;height:100%;padding:24px 28px}._Gi0d{width:100%;margin-bottom:24px}.KZ1QN{width:100%;min-height:632px;background-color:var(--wpp-grey-color-000, #FFFFFF);border-radius:var(--wpp-border-radius-m, 8px);box-shadow:var(--wpp-box-shadow-s);padding:24px 28px;position:relative}.bGSXQ{width:100%}.PnT48{position:absolute;top:50%;right:50%;transform:translate(50%, -50%)}", "",{"version":3,"sources":["webpack://./src/components/pages/tools/content/Content.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,OACE,UAAA,CACA,kBAAA,CAGF,OACE,UAAA,CACA,gBAAA,CACA,mDAAA,CACA,6CAAA,CACA,kCAAA,CACA,iBAAA,CACA,iBAAA,CAGF,OACE,UAAA,CAGF,OACE,iBAAA,CACA,OAAA,CACA,SAAA,CACA,8BAAA","sourcesContent":[".pageContainer {\n  width: 100%;\n  height: 100%;\n  padding: 24px 28px;\n}\n\n.titleBtnWrapper {\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.tableContainer {\n  width: 100%;\n  min-height: 632px;\n  background-color: var(--wpp-grey-color-000, #FFFFFF);\n  border-radius: var(--wpp-border-radius-m, 8px);\n  box-shadow: var(--wpp-box-shadow-s);\n  padding: 24px 28px;\n  position: relative;\n}\n\n.table {\n  width: 100%;\n}\n\n.spinnerContainer {\n  position: absolute;\n  top: 50%;\n  right: 50%;\n  transform: translate(50%, -50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "N5_q1",
	"titleBtnWrapper": "_Gi0d",
	"tableContainer": "KZ1QN",
	"table": "bGSXQ",
	"spinnerContainer": "PnT48"
};
export default ___CSS_LOADER_EXPORT___;
