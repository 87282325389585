import { WppButton, WppEmptyTable, WppTypography } from '@platform-ui-kit/components-library-react'

import styles from './EmptyState.module.scss'
import { useToolsContext } from '../../../../hooks/useToolsContext'
import { Flex } from '../../../common/flex/Flex'

const EmptyState = () => {
  const { setOpenSideModal } = useToolsContext()
  return (
    <Flex justify="center" align="center" className={styles.container}>
      <Flex direction="column" gap={24} align="center" className={styles.contentContainer}>
        <WppEmptyTable width={120} height={120} />
        <WppTypography className={styles.text} type="m-strong">
          You don’t have any AI toolbar tools added in this workspace yet.
        </WppTypography>
        <WppButton variant="primary" size="m" type="button" onClick={() => setOpenSideModal(true)}>
          Add Tool
        </WppButton>
      </Flex>
    </Flex>
  )
}

export default EmptyState
