// src/api/fetchers/apps/fetchApps.ts
import { settingsApi } from 'api'
import { fetchAppsResponse } from 'types/app/app'

export const fetchApps = ({
  tenantId,
  page,
  order_by,
  itemsPerPage,
  externalUser,
}: {
  tenantId?: string
  itemsPerPage: number
  order_by: string
  externalUser: boolean
  page: number
}) =>
  settingsApi.get<fetchAppsResponse>('/apps', {
    params: { tenantId, itemsPerPage, externalUser, page, order_by },
  })
