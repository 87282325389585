import { WppModal, WppButton, WppInput } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/common/warningConfirmModal/WarningConfirmationModal.module.scss'

interface Props {
  open: boolean
  title: string
  bodyText?: string
  isShareModal?: boolean
  onClose: () => void
  onConfirm: (user?: string) => void
}

export const WarningConfirmationModal = ({
  open,
  title,
  bodyText,
  isShareModal = false,
  onClose,
  onConfirm,
}: Props) => {
  const { t } = useTranslation()

  const [form, setForm] = useState({
    user: '',
  })

  return (
    <WppModal open={open} size="s" className={styles.modal}>
      <h3 slot="header">
        <Flex align="center">
          {/* <WppIconWarning /> */}
          <span>{title}</span>
        </Flex>
      </h3>
      {!isShareModal && <p slot="body">{bodyText}</p>}
      {isShareModal && (
        <p slot="body">
          <WppInput
            name="model_name"
            placeholder={bodyText}
            value={form.user}
            onWppChange={e =>
              setForm(prevState => ({
                ...prevState,
                user: e.target.value,
              }))
            }
            required
            className={styles.input}
          />
        </p>
      )}
      <div slot="actions">
        <Flex justify="end" gap={8}>
          <WppButton variant="secondary" size="s" onClick={onClose} color="var(--wpp-primary-color-500)">
            {t('common.cancel')}
          </WppButton>
          <WppButton
            variant="destructive"
            size="s"
            onClick={() => (isShareModal ? onConfirm(form.user) : onConfirm())}
            color="var(--wpp-primary-color-500)"
          >
            {isShareModal ? t('common.share') : t('common.delete')}
          </WppButton>
        </Flex>
      </div>
    </WppModal>
  )
}
